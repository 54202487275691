import React, {useState} from "react";
import ReactMarkdown from "react-markdown";
import SEO from "../components/common/seo";
import Layout from "../components/common/layout";
import {Link} from "@reach/router";
import Partners from "../components/common/partners";
import {customElements, gtagCustomEvent, sortAsc, useFormInput} from "../utils/global-functions";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {graphql} from "gatsby";
import rehypeRaw from "rehype-raw";

const PartnersPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const partners = data.allStrapiPartners.edges[0].node;
    const types = partners.types.sort(sortAsc);
    const name = useFormInput("")
    const surname = useFormInput("")
    const email = useFormInput("")
    const company = useFormInput("")
    const companySize = useFormInput("")
    const phone = useFormInput("")
    const message = useFormInput("")
    const conditions = useFormInput("")

    const {executeRecaptcha} = useGoogleReCaptcha()
    const [success, setSuccess] = useState("")
    const [fail, setFail] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            console.error("executeRecaptcha fail");
            return
        }

        const token = await executeRecaptcha("homepage")

        const json = {
            "contactSalesName": name.value,
            "contactSalesSurname": surname.value,
            "contactSalesEmail": email.value,
            "contactSalesOrganization": company.value,
            "contactSalesOrganizationSize": companySize.value,
            "contactSalesPhone": phone.value,
            "contactSalesMessage": message.value,
            "partners": "true",
            "g-recaptcha-response": token
        }
        const data = JSON.stringify(json)
        const partnersForm = document.getElementById("partners-form")
        const btnSend = document.getElementById("btn-partners-send")

        partnersForm.style.opacity = "0.5"
        btnSend.innerText = "Enviando..."
        btnSend.disabled = true

        fetch(process.env.SPIRIT_URL + "contact-sales-form", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
            .then(response => {
                if (response.ok) return response.json()

                throw new Error("No enviado. No ha sido posible registrar tu mensaje, por favor, contáctanos de otro modo")
            })
            .then(data => {
                partnersForm.style.opacity = "1"
                partnersForm.reset()
                clearForm()
                btnSend.innerText = "Solicitar alta"
                btnSend.disabled = false
                setSuccess(data.msg)
                setFail("")
                hideAlerts()

                gtagCustomEvent("partners.sent", {
                    from: window.location.href,
                    data: json
                })
            })
            .catch(error => {
                partnersForm.style.opacity = "1"
                btnSend.innerText = "Solicitar alta"
                btnSend.disabled = false
                setFail(error.message)
                setSuccess("")
                hideAlerts()
            })
    }

    const hideAlerts = () => {
        setTimeout(() => {
            setSuccess("")
            setFail("")
        }, 10000)
    }

    const clearForm = () => {
        name.clearValue()
        email.clearValue()
        company.clearValue()
        phone.clearValue()
        message.clearValue()
    }

    return (
        <Layout pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={partners.seo.title} description={partners.seo.meta_description}/>

            <section data-speed=".8" className="pt-10 pb-10 pt-md-15 pb-md-14 wt-desktop jarallax"
                     data-jarallax-video={"mp4:" + require("../video/why-tecalis.mp4").default}>
                <div className="overlay-bg bg-pattern-2"/>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-8 col-lg-8">
                            <h3 className="text-white font-weight-bold spacing text-uppercase">
                                {partners.subtitle}
                            </h3>
                            <h1 align="left" className="display-2 mb-8 font-weight-black text-white spacing font-why">
                                {partners.title}<span className="blink">_</span>
                            </h1>
                        </div>
                    </div>
                </div>
            </section>

            <section data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14 wt-mobile bg-white"
                     style={{background: "linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.3)), url('" + require('../images/wt_01.png').default + "')"}}>
                <div className="overlay-bg bg-pattern-2"/>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-8 col-lg-8">
                            <h3 className="text-white font-weight-bold spacing text-uppercase">
                                {partners.subtitle}
                            </h3>
                            <h2 align="left" className="display-2 mb-8 font-weight-black text-white spacing font-why">
                                {partners.title}<span className="blink">_</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-white mt-8 py-5 border-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl">
                            <ReactMarkdown children={partners.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>
                        </div>
                        <div className="col-12 col-xl-auto">
                            <a className="btn btn-primary spacing mt-1 smooth-scroll" href="#unirme" data-offset="0">
                                {partners.join_button}
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="position-relative py-8 py-md-11 bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-5" data-aos="">
                            <h2 className="display-4 font-weight-bold spacing">
                                {partners.connect_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={partners.connect_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8" style={{position: 'absolute', zIndex: '-10', top: '50px'}}>
                                <img src={require('../images/ic_decoration.svg').default} alt="..."/>
                            </div>

                            <div className="vlt-simple-image">
                                <div className="vlt-simple-image__mask" data-aos="image-mask-animation" data-aos-delay="0">
                                    <div className="inside"/>
                                </div>
                                <img src={require('../images/partner1.webp').default} alt="..." className="img-fluid mw-130 float-left mb-6 mb-md-0" data-aos=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-black position-relative pt-9 pb-9 pt-md-12 pb-md-10">
                <div className="container">
                    <div className="row align-items-center">
                        {types.map((type, i) => {
                            return <div className="col-12 col-md-6 programas" key={i}>
                                <div className="card mb-6 mb-md-0 lift">
                                    <div className="card-body">
                                        <br/>
                                        <div align="center" className="mb-5">
                                            <img src={type.icon.localFile.publicURL} className="img-fluid" width="140" alt="Reseller partner"/>
                                        </div>
                                        <h2 align="center" className="font-weight-bold spacing">
                                            <span className="text-tecalis font-weight-black">{type.title}</span> Partner
                                        </h2>
                                        <ReactMarkdown children={type.description} rehypePlugins={[rehypeRaw]} components={customElements('text-black-800 mb-6 spacing ml-6 mr-6')}/>
                                    </div>
                                </div>
                            </div>
                        })}

                        <div className="col-12 col-md-6 programas"/>
                    </div>
                </div>
            </section>

            <section id="unirme" className="bg-grey pt-8 pb-11 pt-md-11">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5" data-aos="fade-up">
                            <h2 align="left" className="display-4 font-weight-bold spacing mt-5">
                                {partners.form_title}
                            </h2>
                            <img src={require('../images/partners.svg').default} className="img-fluid mt-8" alt="Partners"/>
                        </div>

                        <div className="col-xl-1" data-aos="fade-left"/>

                        <div className="col-xl-6" data-aos="fade-left">
                            <div className="card card-border border-success shadow-light-lg mt-5">
                                <div className="card-body">
                                    <form id="partners-form" onSubmit={handleSubmit}>
                                        <div className="form-row mt-3">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-5">
                                                    <input type="text" className="form-control" placeholder={`${partners.form_first_name}*`} required {...name}/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-5">
                                                    <input type="text" className="form-control" placeholder={`${partners.form_last_name}*`} required {...surname}/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-5">
                                                    <input type="email" className="form-control" placeholder={`${partners.form_email}*`} required {...email}/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-5">
                                                    <input type="text" className="form-control spacing" placeholder={`${partners.form_phone}*`} required {...phone}/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group mb-5">
                                                    <input type="text" className="form-control spacing" placeholder={`${partners.form_company}*`} required {...company}/>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group  mb-5">
                                                    <select className="form-control spacing" required {...companySize}>
                                                        <option>{partners.form_company_size}</option>
                                                        <option value="1">0 - 1 empleados</option>
                                                        <option value="2">2 - 10 empleados</option>
                                                        <option value="3">11 - 50 empleados</option>
                                                        <option value="4">51 - 200 empleados</option>
                                                        <option value="5">201 - 500 empleados</option>
                                                        <option value="6">501 - 1,000 empleados</option>
                                                        <option value="7">1001 - 5,000 empleados</option>
                                                        <option value="8">5001 - 10,000 empleados</option>
                                                        <option value="9">10,001+ empleados</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-12 mb-4">
                                                <div className="form-group">
                                                    <label className="sr-only" htmlFor="exampleFormControlTextarea1"/>
                                                    <textarea className="form-control spacing" id="exampleFormControlTextarea1" rows="3" placeholder={partners.form_message} {...message}/>
                                                </div>
                                                <small id="emailHelp" className="form-text text-muted">*{partners.form_required_fields}</small><br/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="checkbox icheck-primary spacing">
                                                    <input type="checkbox" id="primary" {...conditions} required/>
                                                    <label htmlFor="primary">
                                                        <ReactMarkdown children={partners.form_privacy_policies} rehypePlugins={[rehypeRaw]} components={customElements('', 'font-weight-bold')} />
                                                    </label>
                                                </div>
                                                <div align="left">
                                                    {success && <div className="alert alert-success mt-2 animate__animated animate__fadeInDown" role="alert">
                                                        <div align="center" className="font-alert spacing">Gracias. Te responderemos lo antes posible 🙂</div>
                                                    </div>}

                                                    {fail && <div className="alert alert-danger mt-2 animate__animated animate__fadeInDown" role="alert">
                                                        <div align="center" className="font-alert spacing">Se ha producido un error. Inténtalo de nuevo 😔</div>
                                                    </div>}

                                                    <br/>
                                                    <button id="btn-partners-send" type="submit" className="btn btn-primary spacing mb-4" style={{width: '200px'}}>
                                                        {partners.form_button} <i className="fe fe-arrow-right"/>
                                                    </button>
                                                    <br/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Partners pageContext={pageContext}/>
        </Layout>
    )
}

export default PartnersPage

export const partnersPageQuery = graphql
    `query($lang: String) {
    allStrapiPartners (filter: { locale: { eq: $lang } }) {
        edges {
            node {
                seo {
                    title
                    meta_description
                }
                title
                subtitle
                description
                join_button
                connect_title
                connect_description
                types {
                    order
                    title
                    description
                    icon {
                        localFile {
                            publicURL
                        }
                    }
                }
                form_title
                form_first_name
                form_last_name
                form_email
                form_phone
                form_company
                form_company_size
                form_message
                form_required_fields
                form_privacy_policies
                form_button
            }
        }
    }
    cookies: markdownRemark(
        frontmatter : {
            lang: { eq: $lang }
            name: { eq: "cookies" }
        }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
