import React from "react";

const Partners = ({pageContext}) => {
    const text = pageContext.locale.path.default === 'es' ? 'Grandes compañías ya confían en nosotros' : 'Big companies already trust us';

    return (
        <section className="pt-md-8 pb-0 bg-white" id="partners" style={{position: 'relative', zIndex: '9'}}>
            <div className="container pb-6 pb-md-8">
                <div align="center" className="row">
                    <div className="col-md-12">
                        <p align="center" className="spacing text-wall mt-3">{text}</p>

                        <div className="row">
                            <div className="col-6 col-sm">
                                <img
                                    src={require("../../logos/telefonica.png").default} alt="..."
                                    className="footer-brand img-fluid mb-2" width="300"/>
                            </div>
                            <div className="col-6 col-sm">
                                <img
                                    src={require("../../logos/prosegur.png").default} alt="..."
                                    className="footer-brand img-fluid mb-2"
                                    width="300"/>
                            </div>
                            <div className="col-6 col-sm">
                                <img
                                    src={require("../../logos/claro.png").default} alt="..."
                                    className="footer-brand img-fluid mb-2"
                                    width="300"/>
                            </div>
                            <div className="col-6 col-sm">
                                <img
                                    src={require("../../logos/vodafone.png").default} alt="..."
                                    className="footer-brand img-fluid mb-2"
                                    width="300"/>
                            </div>
                            <div className="col-6 col-sm">
                                <img
                                    src={require("../../logos/enel.png").default} alt="..."
                                    className="footer-brand img-fluid mb-2"
                                    width="300"/>
                            </div>
                            <div className="col-6 col-sm">
                                <img
                                    src={require("../../logos/valeo.png").default} alt="..."
                                    className="footer-brand img-fluid mb-2"
                                    width="300"/>
                            </div>
                            <div className="col-6 col-sm">
                                <img
                                    src={require("../../logos/orange.png").default} alt="..."
                                    className="footer-brand img-fluid mb-2"
                                    width="300"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partners
